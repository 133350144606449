import React from 'react';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';

import { Layout, SEO, Welcome, Faq, Footer } from 'components';

const Home = ({ intl, data }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'home_title' })} />
      <Welcome
        logoFluid={data.logo.childImageSharp.fluid}
        appStoreFluid={data.appStore.childImageSharp.fluid}
        playStoreFluid={data.playStore.childImageSharp.fluid}
      />
      <Faq />
      <Footer />
    </Layout>
  );
};

export default injectIntl(Home);

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "leetags-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    appStore: file(relativePath: { eq: "app-store-get.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    playStore: file(relativePath: { eq: "play-store-get.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
